import { fetchApi } from "../../utils/api";
import {
    LOAD_LIST_JOB, LOAD_JD
} from '@/constants/ActionTypes';
export async function getListJob(payload, dispatch) {
    try {
        let response = await fetchApi(`/app/job/get-list-job`, 'get', payload);
        if (response.code !== 200) {
            return {
                data: {},
            };
        }
        return response.data;
    } catch (error) {
        console.log(error);
        return {
            rows: [],
        };
    }
}
export function actionSaveJD(payload) {
    return {
        type: LOAD_JD,
        payload
    };
}
export const getJD = async (payload, dispatch) => {
    const { url } = payload;
    let res = await fetchApi(url, 'get', {
        headers: {
            'Content-Type': 'application/pdf',
        }
    });
    if (res.code === 200) {
        console.log(res);
    }

};
export function actionSaveListJob(payload) {
    return {
        type: LOAD_LIST_JOB,
        payload
    };
}