
import {
    LOAD_SERVICES
} from '../../constants/ActionTypes';
import { fetchApi } from "../../utils/api";
export async function getListProjectWeb(payload, dispatch) {
    try {
        let response = await fetchApi(`/app/project/get-list-project`, 'get', payload);

        if (response.code !== 200) {
            return {
                data: {},
            };
        }
        return response.data;
    } catch (error) {
        console.log(error);
        return {
            rows: [],
        };
    }
}
export async function getListProjectFeature(payload) {
    try {
        let response = await fetchApi(`/app/project/get-list-project-feature`, 'get', payload);

        if (response.code !== 200) {
            return {
                data: {},
            };
        }
        return response.data;
    } catch (error) {
        console.log(error);
        return {
            data: {},
        };
    }
}
