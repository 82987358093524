// const formatCurrency = (n) => {
//     return  (n + '').replace(/./g, function(c, i, a) {
//         return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
//     });
// };


const formatterCurrency = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
    minimumFractionDigits: 0,
});
const formatGold = (num) => {
    if (!num || num === "") return "0";
    let data = Number(num || "0").toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    data = data.replace(/,/g, '-');
    data = data.replace(/\./g, ',');
    data = data.replace(/-/g, '.');
    if (data.indexOf(',000') > -1) {
        data = data.replace(',000', '');
    }
    return data;
};
const formatCurrency = formatterCurrency.format;
export {
    formatCurrency,
    formatGold,
};